
import React from 'react';
// import { useEffect, useState, useContext } from 'react';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


import Container from 'react-bootstrap/Container';
import { Table } from 'react-bootstrap';
import CreateVideo from './CreateVideo';
// import { AccessTokenContext } from './App';
import Header from './Header';

function VideoRow({video}) {
    // console.log("video row: " + video)
  return (
    <tr>
      <td border="1px">{video.video_title}</td>
      <td border="1px" style={{minWidth:'300px'}}><a href="{video.ms_video_url}">{video.ms_video_url}</a></td>
      <td border="1px">{video.status}</td>
      <td border="1px">{video.video_url}</td>
    </tr>
  );
}

function ListVideos({ videos }) {
    const rows = [];
    // console.log("adding videos to table")
    if(videos) {
        videos.forEach((video) => {
            rows.push(
            <VideoRow video={video} key={video.id} />
            );
        });
    }
    
    return (
        <Table striped bordered>
        <thead>
          <tr>
            <th>Title</th>
            <th>Final Video Url</th>
            <th>Status</th>
            <th>Source URL</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        </Table>
    );
}

function Videos() {


//   const {accessToken} = useContext(AccessTokenContext);
  const { getAccessTokenSilently } = useAuth0();

  const [loadingData, setLoadingData] = useState(true);
  const [videoList, setVideoList] = useState([]);

    const apiRoot = "https://video-object.service.mindstamp.my.qa.contextual.io/api";
    const videoPath = apiRoot + "/video";
    let audience = "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/";

    let loadVideos = async function() {
        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience:audience,
                scope: "read:current_user",
            },
        });
        
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}` 
        };
        
        fetch(videoPath, {headers: headers})
        .then(async response => {
            const apiVideos = await response.json();
            setVideoList(apiVideos.items);
            // console.log('got items: ' + videoList)
            // console.log( videoList)
            setLoadingData(false);
        });
    };

    useEffect(() => {
        if(loadingData) {
            loadVideos();
        }
        // console.log("videoList")
        // console.log(videoList)
      });
  

    let newVideoHandler = function(newVideo) {
        if(videoList) {
            setVideoList(videoList => [newVideo, ...videoList]);
        } else {
            setVideoList(videoList => [newVideo]);
        }
    };

  return (
      <Container>
        <Header />
        <br />
        <CreateVideo videos={videoList}  newVideoHandler={newVideoHandler} />
        <br />
        <h1>Videos</h1>
        <ListVideos videos={videoList} />
      </Container>
    );

}

export default Videos;
