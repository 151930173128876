import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function HomeLoggedOut() {

    const { loginWithRedirect } = useAuth0();


    return <div>
          <main role="main">

          <section className="jumbotron text-center" style={{paddingTop: "3rem"}}>
            <div className="container">
              <h1 className="jumbotron-heading">Video maker</h1>
              <p className="lead text-muted">Dynamic videos made easy.</p>
              <p>
                <button className="btn btn-primary my-2" onClick={() => loginWithRedirect()}>Log in</button>
                &nbsp;
                <a href="/" className="btn btn-secondary my-2">Learn more</a>
              </p>
            </div>
          </section>
          <div className="album py-5 bg-light">
        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body">
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm btn-outline-secondary">Show me</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body">
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm btn-outline-secondary">Tell me more</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body">
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm btn-outline-secondary">See it in action</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
          </main>
          </div>;
}

export default HomeLoggedOut;
