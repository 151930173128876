
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button, Container } from 'react-bootstrap';
// import { AccessTokenContext } from './App';
// import { useState, useContext } from 'react';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function CreateVideo({videos, newVideoHandler}) {

    // const {accessToken} = useContext(AccessTokenContext);
    
    const { getAccessTokenSilently } = useAuth0();

    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [buttonUrl, setButtonUrl] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setMessage("")

            console.log("about to create a video")
            
            const oauthAccessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/",
                    scope: "read:current_user",
                },
            });
    
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${oauthAccessToken}`
            };
    
            const apiRoot = "https://video-object.service.mindstamp.my.qa.contextual.io/api";
            const videoPath = apiRoot + "/video";

            const videoDetails = {
                video_title: title,
                video_url: url, 
                video_button_text: buttonText, 
                video_button_click_url: buttonUrl
            };
            
            console.log("sending video params: " + JSON.stringify(videoDetails));


            fetch(videoPath, {headers: headers, method:"POST", body:JSON.stringify(videoDetails)})
            .then(async response => {

                console.log(`got response: (${response.status}) ${response.statusText}`);

                if (response.ok) {
                    let newVideo = await response.json()
                    newVideoHandler(newVideo)
                } else {
                    // response.text().then(t => {throw new Error( t )});
                    throw new Error('Error creating link: ' + response.status);
                }
            })
            .catch((error) => {

                console.log(`got an error: ${error}`);

                setMessage("An error occured");
            });
    
        } catch (err) {
          console.log(err);
        }
      };

    return (
        <Container>
    <Form onSubmit={handleSubmit}>
        <h1>Create a new Video</h1>
        
        <Form.Group as={Row} className="mb-3" controlId="formCreateVideoUrl">
        <Form.Label column sm="2">
            Video Url
        </Form.Label>
        <Col sm="10">
            <Form.Control placeholder="https://" onChange={(e) => setUrl(e.target.value)} />
        </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateVideoTitle">
        <Form.Label column sm="2">
            Title
        </Form.Label>
        <Col sm="10">
            <Form.Control type="text" defaultValue="Video" onChange={(e) => setTitle(e.target.value)} />
        </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateVideoButtonText">
        <Form.Label column sm="2">
            Button Text
        </Form.Label>
        <Col sm="10">
            <Form.Control type="text" defaultValue="Click here" onChange={(e) => setButtonText(e.target.value)} />
        </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateVideoButtonUrl">
        <Form.Label column sm="2">
            Button Url
        </Form.Label>
        <Col sm="10">
            <Form.Control type="text" defaultValue="" onChange={(e) => setButtonUrl(e.target.value)} />
        </Col>
        </Form.Group>
        <br />
        <div className="message" style={{color:"red"}}>{message ? <p>{message}</p> : null}</div>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button variant="primary" type="submit">Create interactive video</Button>
    </Form>

    </Container>

    );
}

export default CreateVideo;
