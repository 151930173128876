import './App.css';
import './HomeLoggedOut.js';

import Home from './Home';

// import React, { useState, createContext } from 'react';
import React, { createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import HomeLoggedOut from './HomeLoggedOut.js';

export const AccessTokenContext = createContext({});

function App() {

  // const [accessToken, setAccessToken] = useState({});
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
        // <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
            <Home />
        // </AccessTokenContext.Provider>
    );
  } else {
    return <HomeLoggedOut />;
  }
}

export default App;
