import { Container } from 'react-bootstrap';
// import React, { useEffect, useContext } from 'react';

import Videos from './Videos';
import ErrorPage from './error-page';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    { path: "/",  element: <Videos />, errorElement: <ErrorPage /> },
    { path: "/videos",  element: <Videos />, errorElement: <ErrorPage /> },
  ]);

export default function Home() {
    
    // const loginCallback = async () => {

    //     const oauthAccessToken = await getAccessTokenSilently({
    //         authorizationParams: {
    //             audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/",
    //             scope: "read:current_user",
    //         },
    //     });
        
    // };

    // useEffect(() => {
    //   loginCallback();
    // });

    
    return (
        <Container>
            <RouterProvider router={router} />
        </Container>
    );
}




